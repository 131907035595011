import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store,{ persistor } from './redux/Store';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-tooltip/dist/react-tooltip.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
    </Provider>
  
);

