import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import useFetch from "../hook/useFetch";
import { BASE_URL } from "../util/Api";
import { useSelector } from "react-redux";


const GeneralAlert = () => {
  const { data } = useFetch("/properties/user_alerts/");
  const [showNotification, setShowNotification] = useState(false);
  const token = useSelector((state) => state.auth.accessToken);
  const bellRef = useRef(null);
  
  const handleNotificationClick = async (id) => {
    // e.preventDefault();
    try {
      const response = await fetch(
        `${BASE_URL}/properties/user_alerts/${id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
          body: JSON.stringify({ read: true }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        // setEditVisible(false);
        window.location.reload()
       console.log("res", res);
      } else{
        console.log("reso", res);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const filteredAlerts = data?.results?.filter((item) => {
    if (!item.read) return true;
    const readAt = new Date(item.read_at);
    const hoursSinceRead = (new Date() - readAt) / (1000 * 60 * 60);
    return hoursSinceRead <= 24;
  });

  // console.log("filteredAlerts ", filteredAlerts);

  
  const unreadCount = filteredAlerts?.filter(
    (item) => item.read === false
  ).length;



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (bellRef.current && !bellRef.current.contains(event.target)) {
        setShowNotification(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [bellRef]);


  return (
    <div className="relative">
      <div
        ref={bellRef}
        className="relative cursor-pointer rounded-full border p-2"
        onClick={() => setShowNotification(!showNotification)}
      >
        <FaBell />
        {unreadCount > 0 && (
          <span className="absolute top-1 right-1 flex h-3 w-3 items-center justify-center text-[10px] font-bold text-white bg-blue-400 rounded-full">
            {unreadCount}
          </span>
        )}
      </div>
      {showNotification && (
        <div
          className="absolute md:right-7 right-5 md:top-6 top-4 mt-2 bg-white border flex flex-col rounded shadow-md"
          ref={bellRef}
        >
          <div className="md:px-4  md:pb-4 pb-2 px-2 pt-2 flex flex-col md:gap-3 gap-1 max-w-[250px] md:text-base">
            <h3 className="md:text-lg text-base pl-2 font-semibold text-start">
              Notifications
            </h3>
            {filteredAlerts?.length > 0 ? (
              data.results.map((item) => (
                <Link
                  to={item.alert.link}
                  target="_blank"
                  key={item.alert.id}
                  onClick={() => handleNotificationClick(item.id)}
                >
                  <div
                    className={`flex items-center cursor-pointer py-1 px-2 rounded-sm gap-2 ${
                      item.read
                        ? "bg-white"
                        : "bg-slate-100"
                    }`}
                  >
                    <div className="flex justify-center items-center w-10 h-10 rounded-full">
                      <img
                        src="/images/appLogo.png"
                        alt=""
                        className="w-8 h-8 object-cover rounded-full"
                      />
                    </div>
                    <div className="truncate">
                      <h1 className="font-semibold">{item?.alert.title}</h1>
                      <p>{item?.alert.message}</p>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="flex items-center py-1 px-2 rounded-sm gap-2">
                <h1 className="font-semibold">There are no notifications</h1>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralAlert;
