// propertySlice.js

import {  createSlice } from '@reduxjs/toolkit';
// import { BASE_URL } from '../../util/Api';

const initialState = {
  // names: {},
  // status: 'idle',
  // error: null,
  properties: null,
  inquiries:null
}

// export const fetchNameById = createAsyncThunk(
//   'property/fetchNameById',
//   async ({ id, url, nameKey, token }, { getState }) => {
//     const response = await fetch(`${BASE_URL}${url}/${id}`, {
//       headers: {
//         Authorization: `JWT ${token}`,
//       },
//     });

//     if (!response.ok) {
//       throw new Error(`Error fetching ${nameKey} from ${url}/${id}`);
//     }

//     const data = await response.json();
//     return { id, nameKey, name: data[nameKey] };
//   }
// );
export const propertySlice = createSlice({
  name: 'property', 
  initialState,
  reducers: {
    // increment: (state) => {
    //   state.asdf += 1
    // },
    // decrement: (state) => {
    //   state.asdf -= 1
    // },
    getProperty: (state, action) => {
      state.properties = action.payload;
    },
    getInquiry: (state, action) => {
      state.inquiries = action.payload;
    },
    logout(state) {
      return initialState; // Reset state to initial state
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchNameById.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(fetchNameById.fulfilled, (state, action) => {
  //       state.status = 'succeeded';
  //       const { id, nameKey, name } = action.payload;
  //       state.names[`${nameKey}_${id}`] = name;
  //     })
  //     .addCase(fetchNameById.rejected, (state, action) => {
  //       state.status = 'failed';
  //       state.error = action.error.message;
  //     });
  // },
})

export const {logout, getProperty,getInquiry } = propertySlice.actions

export default propertySlice.reducer;
