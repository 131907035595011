import React from 'react'
// import { FaFacebook, FaYoutube,  } from 'react-icons/fa'
// import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='p-10 text-center bg-white mt-6'>
      <div className='flex  flex-col md:flex-row items-center gap-5 justify-between'>
        <div className=''>
          <img src="/images/logo.png" alt=""  className='w-40'/>
        </div>
        <div className='flex flex-col  items-start gap-2 font-medium'>
          <Link to="/support">SUPPORT</Link>
          <p>Call us : +91 6356 424 934 </p>
          <p>Whatsapp  :<a href="https://web.whatsapp.com/send?phone=916356424934" target="_blank" rel="noopener noreferrer" className='hover:text-blue-800'> +91 6356 424 934</a> </p>
          <p>Email : support@realtorapp.io</p>
        </div>
        <div className='flex flex-col  items-start gap2 font-medium'>
          <p>Pricing</p>
          <p>FAQs</p>
        </div>
              {/* <div className='flex gap-10'>
   
        <FaFacebook   className='w-14 h-14 text-blue-600 cursor-pointer'/><FaYoutube className='w-14 h-14 text-red-600 cursor-pointer'/><FaSquareXTwitter className='w-14 h-14 cursor-pointer'/>
        </div> */}
      </div>

    </div>
  )
}

export default Footer