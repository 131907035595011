// export   const BASE_URL = "http://127.0.0.1:8000"
import { toast } from "react-toastify";
import store from "../redux/Store"

export const BASE_URL = process.env.REACT_APP_BASE_URL;


const getAccessToken = () => {
    const state = store.getState();
    return state.auth.accessToken; // Adjust based on where the accessToken is stored in your state
  };

// export const fetchDataFromApi = async (url, params) => {
//     try {
//         const response = await fetch(BASE_URL + url + "?" + new URLSearchParams(params), {
//             headers: {
//                 'content-type': 'application/json'
//             }
//         });
//         if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         const data = await response.json();
//         return data;
//     } catch (err) {
//         console.error(err);
//         return err;
//     }
// };


// apiUtils.js

// const handleErrors = (response) => {
//     if (!response.ok) {
//         throw Error(response.statusText);
//     }
//     return response;
// };

// export const postRequest = async (endpoint, data, authToken) => {
    
    
//     try {
//         const response = await fetch(`${BASE_URL}${endpoint}`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `JWT ${authToken}`, // Include your authentication token here
//             },
//             body: JSON.stringify(data),
//         });

//         const result = await handleErrors(response);
        
//         return result.json();
//     } catch (error) {
//         console.error('API Error:', error);
//         throw error;
//     }
// };

export const getRequest = async (endpoint) => {
    const token = getAccessToken()
    try {
        // let Data = {}
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`, // Include your authentication token here
            },
        });
        const data = await response.json()
        if (!response.ok) {
            throw Error(response.statusText);
        }
        
        return data
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};
export const getApiRequest = async (endpoint) => {
    const token = getAccessToken()
    try {
        // let Data = {}
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`, // Include your authentication token here
            },
        });
        const data = await response.json()
        
        if (!response.ok) {
           toast.error(data.details)
        }
        
        return data
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};

export const getPaginationRequest = async (endpoint) => {
    const token = getAccessToken()

    try {
        const fetchAllResults = async (url, accumulatedResults = []) => {
            // Check if the URL is a full URL or a relative path
            const fullUrl = url.startsWith('http') ? url : `${BASE_URL}${url}`;

            const response = await fetch(fullUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${token}`,
                },
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            // Accumulate results from this page
            accumulatedResults = accumulatedResults.concat(data.results);

            // If there's a next page, fetch the next page recursively
            if (data.next) {
                return fetchAllResults(data.next, accumulatedResults);
            }

            // Return the accumulated results along with other data fields
            return {
                count: data.count,
                next: data.next,
                previous: data.previous,
                results: accumulatedResults,
            };
        };

        const data = await fetchAllResults(endpoint);
        return data;
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};
