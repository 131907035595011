


import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from './Slices/authSlice';
import propertySlice from './Slices/propertySlice';
import subUserReducer from './Slices/subUserSlice';
import contactReducer from './Slices/contactSlice';
import namesSlice from './Slices/namesSlice';


const authPersistConfig = {
  key: 'auth',
  storage,
};

const propertyPersistConfig = {
  key: 'property',
  storage,
};
const namesPersistConfig = {
  key: 'names',
  storage,
};


const persistedAuthReducer = persistReducer(authPersistConfig, authSlice);
const persistedPropertyReducer = persistReducer(propertyPersistConfig, propertySlice);
const persistedNamesReducer = persistReducer(namesPersistConfig, namesSlice);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    property: persistedPropertyReducer,
    names :persistedNamesReducer,
    subUsers :subUserReducer,
    contacts :contactReducer,
    
  },
  // devTools: false,



});

export const persistor = persistStore(store);

export default store;
