// src/redux/slices/namesSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../util/Api';

export const namesSlice = createSlice({
  name: 'names',
  initialState: {
    property_types: null,
    property_subtypes: null,
    deal_types: null,
    bhk: null,
    furnitures: null,
    possessions: null,
    areas: null,
    societies: null,
    contacts:null,
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    getPropertyType: (state, action) => {
      state.property_types = action.payload.results;
    },
    getSubType: (state, action) => {
      state.property_subtypes = action.payload.results;
    },
    getDealType: (state, action) => {
      state.deal_types = action.payload.results;
    },
    getbhk: (state, action) => {
      state.bhk = action.payload.results;
    },
    getFurnitures: (state, action) => {
      state.furnitures = action.payload.results;
    },
    getPossessions: (state, action) => {
      state.possessions = action.payload.results;
    },
    getAreas: (state, action) => {
      state.areas = action.payload.results;
    },
    getSocieties: (state, action) => {
      state.societies = action.payload.results;
    },
    getContacts: (state, action) => {
      state.contacts = action.payload.results;
    },
  },
});

export const {
  getDealType,
  getFurnitures,
  getPossessions,
  getPropertyType,
  getSubType,
  getbhk,
  getAreas,
  getSocieties,
  getContacts,
  setLoading,
  setError,
} = namesSlice.actions;

// Thunk to fetch all data except areas and societies
export const fetchAllData = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const token = getState().auth.accessToken;

  const endpoints = [
    { url: `${BASE_URL}/properties/propertytype`, action: getPropertyType },
    { url: `${BASE_URL}/properties/propertysubtype`, action: getSubType },
    { url: `${BASE_URL}/properties/deal_type`, action: getDealType },
    { url: `${BASE_URL}/properties/bhk`, action: getbhk },
    { url: `${BASE_URL}/properties/furniture`, action: getFurnitures },
    { url: `${BASE_URL}/properties/possession`, action: getPossessions },
  ];

  try {
    // Fetch all data in parallel
    const fetchPromises = endpoints.map((endpoint) =>
      fetch(endpoint.url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => dispatch(endpoint.action(data)))
    );

    await Promise.all(fetchPromises);
  } catch (error) {
    dispatch(setError(error.toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

// Thunk to fetch area data
export const fetchAreas = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const token = getState().auth.accessToken;

  try {
    const fetchAllResults = async (url, accumulatedResults = []) => {
        // Check if the URL is a full URL or a relative path
        const fullUrl = url.startsWith('http') ? url : `${BASE_URL}${url}`;

        const response = await fetch(fullUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });
        const data = await response.json();

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        // Accumulate results from this page
        accumulatedResults = accumulatedResults.concat(data.results);

        // If there's a next page, fetch the next page recursively
        if (data.next) {
            return fetchAllResults(data.next, accumulatedResults);
        }

        // Return the accumulated results along with other data fields
        return {
            count: data.count,
            next: data.next,
            previous: data.previous,
            results: accumulatedResults,
        };
    };

    const data = await fetchAllResults("/properties/area");
    dispatch(getAreas(data));
    return data;
} catch (error) {
    console.error('API Error:', error);
    dispatch(setError(error.toString()));
} finally {
    dispatch(setLoading(false));
  }
};
// Thunk to fetch area data
export const fetchContacts = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const token = getState().auth.accessToken;

  try {
    const fetchAllResults = async (url, accumulatedResults = []) => {
        // Check if the URL is a full URL or a relative path
        const fullUrl = url.startsWith('http') ? url : `${BASE_URL}${url}`;

        const response = await fetch(fullUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });
        const data = await response.json();

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        // Accumulate results from this page
        accumulatedResults = accumulatedResults.concat(data.results);

        // If there's a next page, fetch the next page recursively
        if (data.next) {
            return fetchAllResults(data.next, accumulatedResults);
        }

        // Return the accumulated results along with other data fields
        return {
            count: data.count,
            next: data.next,
            previous: data.previous,
            results: accumulatedResults,
        };
    };

    const data = await fetchAllResults("/properties/contacts");
    dispatch(getContacts(data));
    return data;
} catch (error) {
    console.error('API Error:', error);
    dispatch(setError(error.toString()));
} finally {
    dispatch(setLoading(false));
  }
};

// Thunk to fetch society data
export const fetchSocieties = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const token = getState().auth.accessToken;
  try {
    const fetchAllResults = async (url, accumulatedResults = []) => {
        // Check if the URL is a full URL or a relative path
        const fullUrl = url.startsWith('http') ? url : `${BASE_URL}${url}`;

        const response = await fetch(fullUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });
        const data = await response.json();

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        // Accumulate results from this page
        accumulatedResults = accumulatedResults.concat(data.results);

        // If there's a next page, fetch the next page recursively
        if (data.next) {
            return fetchAllResults(data.next, accumulatedResults);
        }

        // Return the accumulated results along with other data fields
        return {
            count: data.count,
            next: data.next,
            previous: data.previous,
            results: accumulatedResults,
        };
    };

    const data = await fetchAllResults("/properties/society");
    dispatch(getSocieties(data));
    return data;
} catch (error) {
    console.error('API Error:', error);
    dispatch(setError(error.toString()));
}
  // try {
  //   const response = await fetch(`${BASE_URL}/properties/society`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `JWT ${token}`,
  //     },
  //   });

  //   const data = await response.json();
  //   dispatch(getSocieties(data));
  // } catch (error) {
  //   dispatch(setError(error.toString()));
  // } 
  
  finally {
    dispatch(setLoading(false));
  }
};

export default namesSlice.reducer;
