
import { createSlice } from '@reduxjs/toolkit';

const subUserSlice = createSlice({
  name: 'subUsers',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    setSubUsers: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { setSubUsers, setLoading, setError } = subUserSlice.actions;
export default subUserSlice.reducer;
