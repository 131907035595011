// src/slices/contactSlice.js
import { createSlice } from '@reduxjs/toolkit';

const contactSlice = createSlice({
  name: 'contacts',
  initialState: {
    list: [],
    source:[],
    contactgroup:[],
    loading: false,
    error: null,
  },
  reducers: {
    setcontacts: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { setcontacts, setLoading, setError } = contactSlice.actions;
export default contactSlice.reducer;
