import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userInfo: null,
    userCompanyInfo:null,
    accessToken: localStorage.getItem("token") || null,
    refreshToken: localStorage.getItem("refresh_token") || null,
    isLoggedIn: false,
    error: null,
    isLoading: false,
  },
  reducers: {
    
    isLoggedIn : (state)=>{
      state.isLoggedIn = true
    },
    initializeAuthState: (state) => {
      const token = localStorage.getItem("token");
      if (token) {
        state.accessToken = token;
        state.isLoggedIn = true;
      }
    },
    loginSuccess: (state, action) => {
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
      state.isLoggedIn = true;
      state.isLoading = false;
    },

    getUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    getUserCompanyInfo: (state, action) => {
      state.userCompanyInfo = action.payload;
    },
    refreshAuthToken: (state, action) => {
      state.accessToken = action.payload;
    },
    nulltoken: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.userInfo = null;
      state.userCompanyInfo= null;
      state.isLoggedIn = false;
      localStorage.removeItem("token")
      state.isLoggedIn = false;
    },
  },
});

export const {
  getUserInfo,
  getUserCompanyInfo,
  logout,
  loginSuccess,
  isLoggedIn,
  initializeAuthState,
  refreshAuthToken,
  nulltoken
} = authSlice.actions;


export default authSlice.reducer;
