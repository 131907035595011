import React, { useCallback, useEffect, useState } from "react";
import { FaBars, FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { jwtDecode } from "jwt-decode";
import { logout as propertyLogout } from "../redux/Slices/propertySlice";
import { Link, useLocation } from "react-router-dom";
import {
  getUserCompanyInfo,
  getUserInfo,
  isLoggedIn,
  refreshAuthToken,
  logout as authLogout,
} from "../redux/Slices/authSlice";
import { BASE_URL } from "../util/Api";
import useFetch from "../hook/useFetch";
import GeneralAlert from "../pages/GeneralAlert";
import { toast } from "react-toastify";

const Header = () => {
  const dispatch = useDispatch();
  // console.log(jwtDecode);
  
  const token = useSelector((state) => state.auth.accessToken);
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.userInfo);
  const [userCompanyData, setUserCompanyData] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const profileLink =
    user?.usertype === "Owner" ? "/profile" : "/subuserprofile";
  const logout = useCallback(() => {
    dispatch(authLogout());
    dispatch(propertyLogout());
    localStorage.removeItem("persist:auth");
    localStorage.removeItem("persist:property");
    localStorage.removeItem("persist:names");
  }, [dispatch]);

  const { data: photo } = useFetch(`/properties/subusers/?user_id=${user?.id}`);

  // console.log("photo",photo);
  

  const handlelogOut = () => {
    logout();
  };
  
  const logoutAllDevices = async (e) => {
    e.preventDefault()
    // setEditLoading(true);
  
  try {
    const responst = await fetch(
      `${BASE_URL}/properties/selflogout_alldevices/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
       
      }
    );
    const data = await responst.json();
    if (responst.ok) {
      toast.success(data.detail);
     
      
        logout();
      
    }else{
      // console.log(data.error);
      
      toast.error( "Error logging out ")
    }
  } catch (error) {
    toast.error("Error logging out ")
  }finally{
    // setEditLoading(false)
  }
};
 
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        if (token) {
          const response = await fetch(`${BASE_URL}/auth/users/me/`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          });
  
          const result = await response.json();
          if (response.ok) {
            dispatch(isLoggedIn());
            dispatch(getUserInfo(result));
  
            // Fetch user profile photo
            const userProfileResponse = await fetch(
              `${BASE_URL}/properties/account/me`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            );
  
            const userProfileData = await userProfileResponse.json();
            if (userProfileResponse.ok) {
              dispatch(getUserCompanyInfo(userProfileData));
              setUserCompanyData(userProfileData);
            } else {
              console.log("Error fetching user profile photo:", userProfileData);
            }
          } else {
            console.log("Error during login:", result);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  

    // generate new access token with accesss token 
    const handleTokenRefresh = async () => {
      try {
        const response = await fetch(`${BASE_URL}/auth/jwt/refresh`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refresh: refreshToken }),
        });
  
        const data = await response.json();
        if (response.ok) {
          dispatch(refreshAuthToken(data.access));
        } else {
          console.error("Token refresh failed:", data);
          console.log("logout from auth/jwt /refresh in responsenot ok");
          logout();
        }
      } catch (error) {
        console.error("Token refresh failed:", error);
        logout();
        console.log("logout from auth/jwt /refresh in catch");
      }
    };
  
    const checkTokenExpiration = () => {
      // console.log("setintervea");
      
      if (!token || !refreshToken) {
        logout()
        return
      };

       const { exp: accessExp } = jwtDecode(token);
      const { exp: refreshExp } = jwtDecode(refreshToken);
      const currentTime = Date.now() / 1000;
      const refreshThreshold = 10* 60;
      const nextCheckTime = currentTime + 120; 
      const accessThreshold=2*60;  
      
      if (refreshExp < currentTime || (refreshExp - currentTime) < refreshThreshold) {
        // If the refresh token is expire or  about to expire in less than 10 minutes, logout
        logout();
        alert("Your session is expired. Please login again!")
        return;
    }
   
    
      else if (accessExp < currentTime) {
          // Access token is expired, check refresh token
         
              // Refresh the access token
              // console.log("Access token is expired, check refresh token,Refresh the access token");
              
              handleTokenRefresh();
         
      } 
      else if ((accessExp - currentTime) < accessThreshold || accessExp < nextCheckTime) {
        // Access token is about to expire within 2 minutes or before the next check, refresh it now
        // console.log("Access token is about to expire within 2 minutes or before the next check, refresh it now");
        
        handleTokenRefresh();
    }
      // else if ((accessExp - currentTime) < refreshThreshold || accessExp < nextCheckTime) {
      //     // Access token is about to expire within 2 minutes or before the next check, refresh it now
      //     handleTokenRefresh();
      // }
  };

  checkTokenExpiration();
     // Check token expiration every 2 minutes (120000 ms) since the access token now lasts for 5 minutes
  const interval = setInterval(checkTokenExpiration, 120000);
  
    fetchData();
  
    return () => clearInterval(interval);
  }, [token, refreshToken, dispatch, logout]);
  
  return (
    <>
      <div className="px-4 py-2 md:px-10 md:py-5 bg-gray-5 flex items-center justify-between bg-white fixed w-full top-0 z-20">
        <Link to="/" className="flex items-center gap-1">
          <img
            src="/images/appLogo.png"
            alt=""
            className=" h-10 w-10 md:w-14 md:h-14 scale-150"
          />
          <span className="md:ml-5 ml-2 font-medium md:text-2xl text-xl">
            RealtorApp.io
          </span>
        </Link>
        {isAuthenticated ? (
          <>
            <div className="flex gap-3 items-center  relative">
              {/* <form className="hidden md:flex">
                <div className="relative w-[300px]">
                  <input
                    type="text"
                    id="location-search"
                    className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border-black border-2 border-gray-30"
                    placeholder="Search"
                    required
                  />
                  <button
                    type="submit"
                    className="absolute top-0 end-0 h-full p-2.5"
                  >
                    <FaSearch />
                  </button>
                </div>
              </form> */}
              <Link
                to="/"
                className="font-medium uppercase p-3 hidden md:block hover:bg-gradient-to-b hover:from-teal-300 hover:to-blue-500 hover:text-white rounded-xl transition-all "
              >
                Home
              </Link>
              <Link
                to="/propertyFilter"
                className="hidden md:block font-medium uppercase p-2 md:p-3  hover:bg-gradient-to-b hover:from-teal-300 hover:to-blue-500 hover:text-white rounded-xl transition-all"
              >
                Properties
              </Link>
              <Link
                to="/projects"
                className="font-medium uppercase p-3 hidden md:block hover:bg-gradient-to-b hover:from-teal-300 hover:to-blue-500 hover:text-white rounded-xl transition-all"
              >
                Projects
              </Link>
              <Link
                to="/inquiryFilter"
                className="font-medium uppercase p-3 hidden md:block hover:bg-gradient-to-b hover:from-teal-300 hover:to-blue-500 hover:text-white rounded-xl transition-all"
              >
                Inquiries
              </Link>
              {(user?.usertype === "Owner" ||
                user?.usertype === "Co-Owner") && (
                <Link
                  to="/contact"
                  className="font-medium uppercase p-3 hidden md:block hover:bg-gradient-to-b hover:from-teal-300 hover:to-blue-500 hover:text-white rounded-xl transition-all"
                >
                  Contacts
                </Link>
              )}
              <Link
                to="/loans"
                className="font-medium uppercase p-3 hidden md:block hover:bg-gradient-to-b hover:from-teal-300 hover:to-blue-500 hover:text-white rounded-xl transition-all"
              >
                Loans
              </Link>
              {/* <div
                ref={bellRef}
                className="relative cursor-pointer rounded-full border p-2"
                onClick={() => setShowNotification(!showNotification)}
              >
                <FaBell />
                {notifications?.results?.length > 0 && (
                  <span className="absolute top-1 right-1 flex h-3 w-3  items-center justify-center  text-[10px]  font-bold text-white bg-blue-400 rounded-full">
                    {notifications?.results?.length}
                  </span>
                )}
              </div> */}
              <div>
                <GeneralAlert />
              </div>
              <div
                className="flex items-center gap-2 cursor-pointer"
                onMouseEnter={() => setDropdownVisible(true)}
                onMouseLeave={() => setDropdownVisible(false)}
                onClick={() => setDropdownVisible(!isDropdownVisible)}
              >
                <div className="flex items-center gap-2">
                  {user?.usertype === "Owner" ? (
                    userCompanyData?.profile_photo ? (
                      <img
                        src={`${userCompanyData.profile_photo}`}
                        alt=""
                        className="w-12 h-12 object-cover rounded-[50%]"
                        
                      />
                    ) : (
                      <FaUserCircle className="w-10 h-10" />
                    )
                  ) : photo?.results?.length > 0 && photo?.results[0]?.subuser_profile_photo ? (
                    <img
                      src={`${photo?.results[0]?.subuser_profile_photo}`}
                      alt=""
                      className="w-12 h-12 object-cover rounded-[50%]"
                    />
                  ) : (
                    <FaUserCircle className="w-10 h-10" />
                  )}
                </div>

                {isDropdownVisible && (
                  <div className="absolute -right-4 md:right-5  top-5  mt-2  bg-white border flex flex-col  rounded shadow-md">
                    <p className="py-2 px-7  bg-slate-200 ">{user?.username}</p>

                    {(user?.usertype === "Owner" ||
                      user?.usertype === "Co-Owner") && (
                      <>
                        {/* <Link
                          to="/dashboard "
                          className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                        >
                          Dashboard
                        </Link>
                        <Link
                          to="/viewProperty"
                          className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                        >
                          Viewed Properties
                        </Link>
                        <Link
                          to="/viewInquiry "
                          className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                        >
                          Viewed Inquiries
                        </Link> */}
                      </>
                    )}
                    <Link
                      to={profileLink}
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Profile
                    </Link>
                    {(user?.usertype === "Owner" ||
                      user?.usertype === "Co-Owner") && (
                      <Link
                        to="/subuser "
                        className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                      >
                        Subuser
                      </Link>
                    )}

                    <Link
                      to="/paymentdetail"
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Subscription
                    </Link>
                    <Link
                      to="/arealist "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Areas List
                    </Link>
                    <Link
                      to="/societyList "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Society List
                    </Link>
                    <Link
                      to="/sources "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Source List
                    </Link>
                    <Link
                      to="/contactgroup "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Contact Group List
                    </Link>
                    <Link
                      to="/sitevisits "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      SiteVisits
                    </Link>
                    {(user?.usertype === "Owner" ||
                      user?.usertype === "Co-Owner") && (
                      <Link
                        to="/deals "
                        className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                      >
                        Deals
                      </Link>
                    )}
                    <Link
                      to="/99acres "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      99acres
                    </Link>
                    <Link
                      to="/datamigration "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Data Migration
                    </Link>
                    <button
                      className="block w-full text-left py-2 px-7  hover:bg-gray-100 hover:font-bold"
                      onClick={handlelogOut}
                    >
                      Logout
                    </button>
                    
                    <button
                      className="block w-full text-left py-2 px-7  hover:bg-gray-100 hover:font-bold"
                      onClick={logoutAllDevices}
                    >
                      Logout All Devices
                    </button>
                  </div>
                )}
              </div>
              {/* {showNotification && (
                <div className="absolute -right-4   md:right-24  top-8  mt-2  bg-white border flex flex-col  rounded shadow-md"  ref={bellRef}>
                  <GeneralAlert  data={notifications}/>
                </div>
              )} */}
              <div>
                <div
                  className="md:hidden cursor-pointer "
                  onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                >
                  <FaBars className="w-6 h-6" />
                </div>
                <div
                  className={`md:hidden absolute -right-4  text-center top-0  bg-white  w-[100vw] ${
                    isMobileMenuOpen ? "block" : "hidden"
                  }`}
                >
                  <Link
                    to="/"
                    className=" px-3 py-2 text-xl block md:hidden font-medium"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Home
                  </Link>
                  <Link
                    to="/dashboard"
                    className=" px-3 py-2 text-xl block md:hidden font-medium"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Dashboard
                  </Link>
                  <Link
                    to="/viewProperty"
                    className=" px-3 py-2 text-xl block md:hidden font-medium"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Viewed Properties
                  </Link>
                  <Link
                    to="/viewInquiry"
                    className=" px-3 py-2 text-xl block md:hidden font-medium"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Viewed Inquiries
                  </Link>
                  <Link
                    to="/propertyFilter"
                    className=" px-3 py-2 text-xl block md:hidden  font-medium"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Properties
                  </Link>
                  <Link
                    to="/projects"
                    className=" px-3 py-2 text-xl block md:hidden  font-medium"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Projects
                  </Link>
                  <Link
                    to="/inquiryFilter"
                    className=" px-3 py-2 text-xl block md:hidden  font-medium"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Inquiries
                  </Link>
                  <Link
                    to="/contact"
                    className=" px-3 py-2 text-xl block md:hidden font-medium"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Contacts
                  </Link>
                  <Link
                    to="/loans"
                    className=" px-3 py-2 text-xl block md:hidden font-medium"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Loans
                  </Link>
                  <IoClose
                    className="absolute right-2  top-0 w-10 h-10"
                    onClick={() => setMobileMenuOpen(false)}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {location?.pathname !== "/login" ? (
              <div className=" md:flex md:gap-3 ">
                <Link
                  to="/login"
                  className="font-medium uppercase p-2 md:p-3  hover:bg-gradient-to-b hover:from-teal-300 hover:to-blue-500 hover:text-white md:rounded-xl rounded-sm  transition-all"
                >
                  Login
                </Link>
              </div>
            ) : (
              <div className=" md:flex md:gap-3 ">
                <Link
                  to="/register"
                  className="font-medium uppercase p-2 md:p-3  hover:bg-gradient-to-b hover:from-teal-300 hover:to-blue-500 hover:text-white md:rounded-xl rounded-sm transition-all"
                >
                  Register
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Header;
