import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import useFetch from "../../hook/useFetch";
import { BASE_URL } from "../../util/Api";

const BulkProperty = () => {
  const [extractedData, setExtractedData] = useState([]);
  const user = useSelector((state) => state.auth.userInfo);
  // const [propData, setPropData] = useState([])
  const [areas, setAreas] = useState([]);
  const token = useSelector((state) => state.auth.accessToken);
  const { data: cityData } = useFetch("/properties/account/me/");
  const { data: citydata } = useFetch("/properties/viewaccount/");
  const [loading, setLoading] = useState(false);

  const city =
    user?.usertype === "Owner"
      ? cityData
      : citydata?.results?.length > 0
      ? citydata?.results[0]
      : null;

  React.useEffect(() => {
    const fetchAreas = async () => {
      try {
        const response = await fetch(`${BASE_URL}/properties/area/`, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAreas(data.results);
        } else {
          toast.error("Failed to fetch areas");
        }
      } catch (error) {
        console.log("Error fetching areas:", error);
      }
    };

    fetchAreas();
  }, [token]);

  // console.log("areas",areas);
  

//   };
const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      // Helper function to convert text to Title Case (Aa format)
      const toTitleCase = (str) => {
        return str
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };
  
      const formattedData = jsonData?.map((row, index) => ({
        // srNo: index + 1,
        unit_no: row?.unit_no || "",
        city: row?.city || "",
        address: row?.address || "",
        area: row?.area ? toTitleCase(row?.area) : "",
        society_name: row?.society_name ? toTitleCase(row?.society_name) : "",
        google_location: row?.google_location || "",
        property_type: row?.property_type || "",
        property_sub_type: row?.property_sub_type || "",
        total_floor: row?.total_floor || "",
        on_floor: row?.on_floor || "",
        bhk: row?.bhk || "",
        furniture: row?.furniture || "",
        deal_type: row?.deal_type || "",
        plot_size: row?.plot_size || "",
        construction_size: row?.construction_size || "",
        size_unit: row?.size_unit || "",
        price: row?.price || "",
        price_comments: row?.price_comments || "",
        rent: row?.rent || "",
        tax: row?.tax || "",
        maintenance: row?.maintenance || "",
        facing: row?.facing || "",
        year_of_construction: row?.year_of_construction || "",
        brokerage: row?.brokerage || "",
        keys: row?.keys || "",
        comments: row?.comments || "",
        available_from: row?.available_from || "",
        parking: row?.parking || "",
        image_1: row?.image_1 || "",
        image_2: row?.image_2 || "",
        image_3: row?.image_3 || "",
        image_4: row?.image_4 || "",
        image_5: row?.image_5 || "",
        image_6: row?.image_6 || "",
        image_7: row?.image_7 || "",
        image_8: row?.image_8 || "",
        image_9: row?.image_9 || "",
        image_10: row?.image_10 || "",
        video: row?.video || "",
        video_link: row?.video_link || "",
        contact: row?.contact || "",
        nonveg_allowed: row?.nonveg_allowed || false,
        bachelor_allowed: row?.bachelor_allowed || false,
        can_be_used_for_commercial_use: row?.can_be_used_for_commercial_use || false,
        available: row?.available || false,
      }));
  
      setExtractedData(formattedData);
    };
  
    reader.readAsArrayBuffer(file);
  };
  
  const getAreaId = async (areaName) => {
    // Sanitize the area name
    // console.log("areaname",areaName);
    let updatedareass = areas

    const sanitizedAreaName = areaName.trim().toLowerCase();
    // console.log("area", updatedareass);

    // Find the area by name after sanitization
    const existingArea = updatedareass.find(
      (area) => area.area.trim().toLowerCase() === sanitizedAreaName
    );

    // console.log("Sanitized area name:", sanitizedAreaName);
    // console.log("Found existing area:", existingArea);

    if (existingArea) {
      console.log("existingareas", existingArea);
      
      return existingArea.id;
    }

    // Attempt to create a new area if it does not exist
    try {
      const response = await fetch(`${BASE_URL}/properties/area/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify({
          area: sanitizedAreaName,
          city: city.default_city,
        }),
      });

      if (response.ok) {
        const newArea = await response.json();
         updatedareass = [...areas, newArea];
        setAreas((prev) => {
          const updatedAreas = [...prev, newArea];
          console.log("Updated areas:", updatedAreas); // Use this updated state directly
          return updatedAreas;
        });
        return newArea.id;
      } else {
        const errorData = await response.json();
        console.log("Error creating area:", errorData);
        toast.error(
          "Failed to create area: " + (errorData?.detail || "Unknown error")
        );
        return null;
      }
    } catch (error) {
      console.log("Error creating area:", error);
      toast.error("Error creating area: " + error.message);
      return null;
    }
  };

  const generateExcelTemplate = () => {
    // Sample data for the Excel file
    const sampleData = [
        {
            unit_no: "",
            city: null,
            address: "",
            area: [],
            society_name: null,
            google_location: "",
            property_type: null,
            property_sub_type: [],
            total_floor: null,
            on_floor: null,
            bhk: [],
            furniture: [],
            deal_type: [],
            plot_size: null,
            construction_size: null,
            size_unit: null,
            price: null,
            price_comments: "",
            rent: null,
            tax: null,
            maintenance: null,
            facing: null,
            year_of_construction: null,
            brokerage: null,
            keys: "",
            comments: "",
            available_from: null,
            parking: "",
            image_1: null,
            image_2: null,
            image_3: null,
            image_4: null,
            image_5: null,
            image_6: null,
            image_7: null,
            image_8: null,
            image_9: null,
            image_10: null,
            video: null,
            video_link: "",
            contact: null,
            nonveg_allowed: false,
            bachelor_allowed: false,
            can_be_used_for_commercial_use: false,
            available: false
        }
        
      
    ];

    // Create a new worksheet
    const worksheet = XLSX.utils.json_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

    // Convert the workbook to a binary string
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a blob from the buffer
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the blob and trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Properties.xlsx";
    link.click();
    window.URL.revokeObjectURL(url); // Clean up the URL object
  };

  // Function to fetch existing societies for a given area ID
  const fetchExistingSocieties = async (areaId) => {
    try {
      const response = await fetch(
        `${BASE_URL}/properties/society/?area_id=${areaId}`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        return data.results.map((society) =>
          society.society_name.toLowerCase()
        );
      } else {
        toast.error("Failed to fetch existing societies");
        return [];
      }
    } catch (error) {
      console.log("Error fetching existing societies:", error);
      return [];
    }
  };
// console.log("extracted data",extractedData);


const mapDataForSubmission = async () => {
  const city_id = city.default_city;
  const formattedSubmission = [];
  const areasTobeAdded  = new Set();


  for (const area of extractedData) {
    if (areasTobeAdded.has(area.area)) {
      // Skip if this area has already been processed
      continue;
    }
    try {
      console.log("Processing area:", area.area);
      areasTobeAdded.add(area.area)
      const areaId = await getAreaId(area.area);
      if (!areaId) {
        console.log(`No area ID found for: ${area.area}`);
        continue; // Skip to the next area if no ID found
      }

      console.log("Found area ID:", areaId);
      const existingSocieties = await fetchExistingSocieties(areaId);
      console.log("Existing societies:", existingSocieties);
      
      
      const societiesToSubmit = extractedData.filter(a => a.area === area.area)
      const asdlfka= societiesToSubmit.flatMap(a=>a.society_name)
      // Filter all societies for this area
      // .flatMap(a => a.societies) // Flatten the societies into a single array
      // .filter(society => !existingSocieties.includes(society.society_name.toLowerCase())); // Filter out existing societies
      const uniqueSocietiesSet = new Set(asdlfka);
      const uniqueSocieties = Array.from(uniqueSocietiesSet);
      console.log("societies to submit",uniqueSocieties);
      
      
      
     // Add unique societies to the formatted submission
     societiesToSubmit.forEach(society => {
      formattedSubmission.push({
        city_id: city_id,
        area_id: areaId,
        society_name: society.society_name,
      });
    });
    } catch (error) {
      console.error(`Error processing area: ${area.area}`, error);
    }
  }

  return formattedSubmission;
};


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const societiesToSubmit = await mapDataForSubmission();

      if (societiesToSubmit.length === 0) {
        toast.info("No new societies to add.");
        return;
      }

      const response = await fetch(
        `${BASE_URL}/properties/society/bulk_create/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
          body: JSON.stringify(societiesToSubmit),
        }
      );

      const res = await response.json();
      if (response.ok) {
        toast.success("Societies added successfully");
      } else {
        toast.error(
          res.non_field_errors?.length > 0
            ? res.non_field_errors[0]
            : "Error adding societies"
        );
      }
    } catch (error) {
      console.log("Error submitting societies:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto pt-[120px] ">
      <h2 className="text-2xl font-bold text-center mb-4 text-white pb-10">
        Add Properties in Bulk
      </h2>
      <div className="flex gap-10 items-center justify-between mb-5">
        <div className="flex gap-5 items-center">
          <label htmlFor="" className="text-lg text-white">
            Upload a excel file of given Template :
          </label>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            className="border p-2"
          />
        </div>
        <div className="flex justify-center mb-4">
          <button
            onClick={generateExcelTemplate}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg h-fit"
          >
            Download Excel Template
          </button>
        </div>
      </div>

      <div className="bg-white p-4 rounded-md shadow-md">
        <h3 className="text-xl font-semibold mb-3">
          {extractedData?.length > 0 ? "Extracted Data" : "Sample Data"} :
        </h3>
        <div className="overflow-x-scroll">
                    <table className="  text-sm text-left rtl:text-right text-gray-500  mt-5 ">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Sr No.
                          </th>
                         
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Area
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Unit No.
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            City
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Address
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Society Name
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Google Location
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Property Type 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Property Sub type
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Total Floor
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            On Floor
                          </th>

                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            BHK
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Furniture
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Deal type
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Plot size
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Construction size
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Size Unit
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Price 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Price Comment
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Rent  
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Tax 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Maintenance 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Facing  
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Years of construction 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Brokerage 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Keys 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Comments 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Available From 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                           Parking
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Image 1 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Image 2 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Image 3 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Image 4 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Image 5
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Image 6
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Image 7
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Image 8
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Image 9
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Image 10
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Video
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Video Link
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Contact 
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Nonveg Allowed
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Bachelor Allowed
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Can Be use for Commercial use
                          </th>
                          <th
                            scope="col"
                            className="p-5 text-center border border-black"
                          >
                            Available
                          </th>
                          {/* <th
                          scope="col"
                          className="p-5 text-center border border-black"
                        >
                          Status
                        </th> */}
                        </tr>
          </thead>
          <tbody>
            {extractedData.length > 0 ? (
              extractedData?.map((item, index) => (
                <tr key={index} className="bg-white border-b text-center">
                  <td className="px-6 py-4 border border-black">{index + 1}</td>
                  <td className="px-6 py-4 border border-black">{item.area}</td>
                  <td className="px-6 py-4 border border-black"> {item.unit_no}</td>
                  <td className="px-6 py-4 border border-black"> {item.city}</td>
                  <td className="px-6 py-4 border border-black"> {item.address}</td>
                  <td className="px-6 py-4 border border-black"> {item.society_name}</td>
                  <td className="px-6 py-4 border border-black"> {item.google_location}</td>
                  <td className="px-6 py-4 border border-black"> {item.property_type}</td>
                  <td className="px-6 py-4 border border-black"> {item.property_sub_type}</td>
                  <td className="px-6 py-4 border border-black"> {item.total_floor}</td>
                  <td className="px-6 py-4 border border-black"> {item.on_floor}</td>
                  <td className="px-6 py-4 border border-black"> {item.bhk}</td>
                  <td className="px-6 py-4 border border-black"> {item.furniture}</td>
                  <td className="px-6 py-4 border border-black"> {item.deal_type}</td>
                  <td className="px-6 py-4 border border-black"> {item.plot_size}</td>
                  <td className="px-6 py-4 border border-black"> {item.construction_size}</td>
                  <td className="px-6 py-4 border border-black"> {item.size_unit}</td>
                  <td className="px-6 py-4 border border-black"> {item.price}</td>
                  <td className="px-6 py-4 border border-black"> {item.price_comments}</td>
                  <td className="px-6 py-4 border border-black"> {item.rent}</td>
                  <td className="px-6 py-4 border border-black"> {item.tax}</td>
                  <td className="px-6 py-4 border border-black"> {item.maintenance}</td>
                  <td className="px-6 py-4 border border-black"> {item.facing}</td>
                  <td className="px-6 py-4 border border-black"> {item.year_of_construction}</td>
                  <td className="px-6 py-4 border border-black"> {item.brokerage}</td>
                  <td className="px-6 py-4 border border-black"> {item.keys}</td>
                  <td className="px-6 py-4 border border-black"> {item.comments}</td>
                  <td className="px-6 py-4 border border-black"> {item.available_from}</td>
                  <td className="px-6 py-4 border border-black"> {item.parking}</td>
                  <td className="px-6 py-4 border border-black"> {item.image_1}</td>
                  <td className="px-6 py-4 border border-black"> {item.image_2}</td>
                  <td className="px-6 py-4 border border-black"> {item.image_3}</td>
                  <td className="px-6 py-4 border border-black"> {item.image_4}</td>
                  <td className="px-6 py-4 border border-black"> {item.image_5}</td>
                  <td className="px-6 py-4 border border-black"> {item.image_6}</td>
                  <td className="px-6 py-4 border border-black"> {item.image_7}</td>
                  <td className="px-6 py-4 border border-black"> {item.image_8}</td>
                  <td className="px-6 py-4 border border-black"> {item.image_9}</td>
                  <td className="px-6 py-4 border border-black"> {item.image_10}</td>
                  <td className="px-6 py-4 border border-black"> {item.video}</td>
                  <td className="px-6 py-4 border border-black"> {item.video_link}</td>
                  <td className="px-6 py-4 border border-black"> {item.contact}</td>
                  <td className="px-6 py-4 border border-black"> {item.nonveg_allowed}</td>
                  <td className="px-6 py-4 border border-black"> {item.bachelor_allowed}</td>
                  <td className="px-6 py-4 border border-black"> {item.can_be_used_for_commercial_use}</td>
                  <td className="px-6 py-4 border border-black"> {item.available}</td>
                </tr>
              ))
            ) : (
              <>
                <tr className="bg-white border-b">
                    <td className="px-6 py-4 text-center" colSpan="18">
                      No Data found.
                    </td>
                  </tr>
                
              </>
            )}
          </tbody>
        </table></div>
        {loading && <p className="text-lg font-bold text-center mt-10">Data Uploading might take time. Do not leave the Page</p>}
        {extractedData.length > 0 && (
          <div className="flex justify-center my-5">
            
            <button
              onClick={handleSubmit}
              className="text-white w-36 bg-gradient-to-b flex justify-center  from-teal-300 to-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md px-5 py-2.5 text-center"
            >
              {loading ? (
                <div className="text-center  border-gray-300 h-6 w-6 animate-spin rounded-full border-2 border-t-blue-600" />
              ) : (
                "Submit"
              )}{" "}
            </button>
          </div>
        )}
        
      </div>

      {/* {!loading &&   <img src="/images/data-transfer.gif" alt="" /> } */}
    </div>
  );
};

export default BulkProperty;
